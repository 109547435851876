<template>
    <div>
        <!-- Desktop menu -->
        <div class="flex items-center w-full h-20" :class="{'theme-bg-grey6 fixed bg-white top-0 z-40 shadow-md': !scrolAtTop || !isHomePage || showDesktopMenu, 'sm:custom-hidden': !showMobileMenu, 'md:hidden': mdHidden }">
            <nav class="hidden w-full md:block" v-show="!showMenu">
                <ul class="relative z-40 flex items-center px-6 text-sm text-white lg:text-base">
                    <li class="mx-2 lg:mx-3">
                        <div @click="goTo('homepage')" class="w-1/4 py-4 pl-6 pr-4 md:pl-4 md:py-0 cursor-pointer">
                            <span @click="goTo('homepage')" class="text-3xl font-black leading-none text-white select-none logo">
                              <img :src="logo" class="welcome-page-logo" />
                            </span>
                        </div>
                    </li>
                    <li class="mx-auto"> </li>
                    <li class="mx-2 lg:mx-3">
                        <div @click="goTo('homepage')" x-data="{ hover: false }" @mouseenter="hover = true" @mouseleave="hover = false" class="relative inline-block font-medium text-gray-200 hover:text-white cursor-pointer" :class="{ 'text-gray-900 hover:text-gray-600': !scrolAtTop || !isHomePage || showDesktopMenu }">
                            <span class="block uppercase">Accueil</span>
                            <span class="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">
                                <span x-show="hover" class="absolute inset-0 inline-block w-full h-1 h-full transform border-t-2 border-pink-500" x-transition:enter="transition ease-out duration-300" x-transition:enter-start="-translate-x-full" x-transition:enter-end="translate-x-0" x-transition:leave="transition ease-out duration-300" x-transition:leave-start="translate-x-0" x-transition:leave-end="translate-x-full"></span>
                            </span>
                        </div>
                    </li>
                    <li class="mx-2 lg:mx-3">
                        <div @click="goTo('product-list')" x-data="{ hover: false }" @mouseenter="hover = true" @mouseleave="hover = false" class="relative inline-block font-medium text-gray-200 hover:text-white cursor-pointer" :class="{ 'text-gray-900	hover:text-gray-600': !scrolAtTop || !isHomePage || showDesktopMenu }">
                            <span class="block uppercase">Nos produits</span>
                            <span class="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">
                                <span x-show="hover" class="absolute inset-0 inline-block w-full h-1 h-full transform border-t-2 border-pink-500" x-transition:enter="transition ease-out duration-300" x-transition:enter-start="-translate-x-full" x-transition:enter-end="translate-x-0" x-transition:leave="transition ease-out duration-300" x-transition:leave-start="translate-x-0" x-transition:leave-end="translate-x-full"></span>
                            </span>
                        </div>
                    </li>
                    <li class="mx-2 lg:mx-3">
                        <div @click="goTo('contact')" x-data="{ hover: false }" @mouseenter="hover = true" @mouseleave="hover = false" class="relative inline-block font-medium text-gray-200 hover:text-white cursor-pointer" :class="{ 'text-gray-900	hover:text-gray-600': !scrolAtTop || !isHomePage || showDesktopMenu }">
                            <span class="block uppercase">Contact</span>
                            <span class="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">
                                <span x-show="hover" class="absolute inset-0 inline-block w-full h-1 h-full transform border-t-2 border-pink-500" x-transition:enter="transition ease-out duration-300" x-transition:enter-start="-translate-x-full" x-transition:enter-end="translate-x-0" x-transition:leave="transition ease-out duration-300" x-transition:leave-start="translate-x-0" x-transition:leave-end="translate-x-full"></span>
                            </span>
                        </div>
                    </li>
                    <li v-show="!showDesktopMenu" class="mx-2 mt-3 lg:mx-3" @click="openCartNavbar">
                        <div x-data="{ hover: false }" @mouseenter="hover = true" @mouseleave="hover = false" class="relative inline-block font-medium text-gray-200 hover:text-white cursor-pointer" :class="{ 'text-gray-900 hover:text-gray-600': !scrolAtTop || !isHomePage || showDesktopMenu }">
                            <span class="block">
                              <svg class="flex-1 w-8 h-8 fill-current" viewbox="0 0 24 24">
                                <path d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z"/>
                                </svg>
                                <span class="absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center"> {{ cartItems.length }}
                              </span>
                            </span>

                            <span class="absolute bottom-0 left-0 inline-block w-full h-1 -mb-1 overflow-hidden">
                                <span x-show="hover" class="absolute inset-0 inline-block w-full h-1 h-full transform border-t-2 border-pink-500" x-transition:enter="transition ease-out duration-300" x-transition:enter-start="-translate-x-full" x-transition:enter-end="translate-x-0" x-transition:leave="transition ease-out duration-300" x-transition:leave-start="translate-x-0" x-transition:leave-end="translate-x-full"></span>
                            </span>
                        </div>
                    </li>
                </ul>
            </nav>
            <!-- End Desktop menu -->

            <!-- Mobile Nav  -->
            <nav class="fixed top-0 z-30 flex flex-col flex-wrap items-center justify-between w-full h-auto px-6 md:hidden" :class="{ 'bg-white': changeBgColor }">
                <div class="relative z-30 flex items-center justify-between w-full h-20">
                    <a @click="goTo('homepage')" class="flex items-center flex-shrink-0 mr-6 text-white">
                        <span class="text-3xl font-black leading-none text-white select-none logo">
                          <img :src="logo" class="welcome-page-logo" />
                        </span>
                    </a>
                    <div class="block lg:hidden">
                        <button @click="showMenu = !showMenu" class="btn-show-menu flex items-center justify-center w-10 h-10 text-gray-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-25 focus:outline-none" :class="{ 'text-gray-800': !scrolAtTop || !isHomePage || showDesktopMenu }">
                            <svg class="w-5 h-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
            <!-- End Mobile Nav -->
        </div>

        <!-- Mobile Menu -->
        <div v-show="showMenu" class="fixed transition duration-500 ease-in-out top-0 z-50 flex flex-col items-center justify-center w-full h-full space-y-5 text-lg origin-center bg-secondary">
            <span @click="goTo('homepage')" class="block text-gray-200 hover:text-white uppercase">Accueil</span>
            <span @click="goTo('product-list')" class="block text-gray-200 hover:text-white uppercase">Nos produits</span>
            <span @click="goTo('contact')" class="block text-gray-200 hover:text-white uppercase">Contact</span>
        </div>
        <!-- End Mobile Menu -->
            
        <div v-show="showMenu" @click="showMenu = false" class="close-menu z-50 fixed top-0 right-0 mr-4 mt-3 cursor-pointer text-white hover:opacity-75 p-2 transition ease-out duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
    </div>
</template>

<script>

import moduleSettingManagement from '@/store/settings/moduleSettingManagement.js'

export default {
  name: "Navbar",
  props: {
    mdHidden: {
      default: false
    },
    changeBgColor: {
      default: false
    },
    showDesktopMenu: {
      default: false
    },
    isHomePage: {
      default: false
    }
  },

  data: () => ({
    scrolAtTop: false,
    showMobileMenu: true,
  }),
  computed: {
    windowTop: {
        get() { 
            return this.$store.state.windowTop; 
        },
        set(val) { 
            this.$store.commit('SET_WINDOW_TOP', val) 
        }
    },
    showMenu: {
        get() { 
            return this.$store.state.showMenu; 
        },
        set(val) { 
            this.$store.commit('SET_SHOW_MENU', val) 
        }
    },
    logo () {
      var customerSetting = this.$store.state.settingManagement.customerSetting
      return customerSetting ? customerSetting.logo : ''
    },
    cartItems () {
      return this.$store.state.eCommerce.cartItems
    },
  },
  methods: {
    openCartNavbar() {
      this.$store.commit("IS_CART_NAVBAR_ACTIVE", true);
    },
    handleScroll (event) {
      // Any code to be executed when the window is scrolled
      let windowTop = window.top.scrollY /* or: e.target.documentElement.scrollTop */
      
      this.$store.commit('SET_WINDOW_TOP', windowTop) 

      // this.scrolAtTop = (windowTop > 200) ? false : true
      this.showMobileMenu = (windowTop > 2000) ? false : true
    },
    goTo(refName) {
      console.log('this.isHomePage', this.isHomePage)
      if(this.isHomePage == true) {
        this.$emit('goToSectionById', refName)
      }
      else {
        console.log('refName', refName)
        this.$router.push({ path: `/${this.$route.params.slug}/welcomepage#${refName}` })
      }
    },
    toggleMenu(val) {
      this.$store.commit("SET_SHOW_MENU", val);
    },
  },
  
  created () {
    window.addEventListener('scroll', this.handleScroll);

    if (!moduleSettingManagement.isRegistered) {
      this.$store.registerModule('settingManagement', moduleSettingManagement)
      moduleSettingManagement.isRegistered = true
    }
  }

}
</script>

<style lang="css">

@import "https://unpkg.com/tailwindcss@2.0.2/dist/tailwind.min.css";

.bg-secondary, a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(var(--vs-secondary), 1)!important;
}

.welcome-page-logo{
  max-width: 9rem;
}

@media (max-width: 640px){
  .sm\:custom-hidden {
      visibility: hidden;
  }
  .welcome-page-logo{
    max-width: 100%;
    height: 50px;
  }
}

</style>